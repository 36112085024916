import { h, Component } from 'preact';
import './alerter.css';

const Alerter = ({message, option1, option2, action1, action2}) => {
    return (
        <div class="alerter">
            <div class="alerter-body">
                <p class="alerter-message">{message}</p>
                <div class="alerter-options">
                     <button class="alerter-option" onClick={()=>action1()}>{option1}</button>
                     <button class="alerter-option" onClick={()=>action2()}>{option2}</button>

                </div>
               
            </div>
        </div>
    );
};

export default Alerter;