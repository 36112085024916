import { h, Component } from 'preact';
import { useState } from 'preact/hooks';
import axios from 'axios';
import Alerter from '../../../components/Alerter';
import logo from "../../../assets/logo.png";


const Forgot = () => {
    const [btnText, setBtnText] = useState('Reset');
    const [contact, setContact] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [cpass, setCpass] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [otpBtnText, setOtpBtnText] = useState('Update Account');

    //custom alerts
    const [alerter, setAlerter] = useState(false);
    const [messager, setMessager] = useState('');
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [action1, setAction1] = useState(() => () => { () => setAlerter(false) });
    const [action2, setAction2] = useState(() => () => { () => setAlerter(false) });
    //custom alerts


    const login = () => {
        console.log('Login presssed');
        setBtnText('please wait....');
        if (contact.trim().length !== 9) {
            setMessager('please enter a valid contact');
            setOption1('Close');
            setOption2('Okay');
            setAction1(() => () => { setAlerter(false) });
            setAction2(() => () => { setAlerter(false) });
            setAlerter(true);

            //alert('please enter a valid contact');
            setBtnText('try again');
        }
        else {
            setBtnText('Requesting OTP...');
            var data = JSON.stringify({
                "contact": "254" + contact,
            });
            var config = {
                method: 'post',
                url: 'https://dakabets.web.app/auth/forgot',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    setConfirm(true);
                })
                .catch(function (error) {
                    setMessager('Problem resetting your account. Contact customer care or try again.');
                    setOption1('Try Again');
                    setOption2('Contact Customer Care');
                    setAction1(() => () => { setAlerter(false) });
                    setAction2(() => () => { location.href = 'tel:0713570000' });
                    setAlerter(true);

                    //console.log(error);

                });
        }
    }

    const verify = () => {
        setOtpBtnText('Verifying OTP...')
        if (otp < 1000) {
            //alert('Enter a valid otp code');
            setMessager('Enter a valid otp code');
            setOption1('Close');
            setOption2('Okay');
            setAction1(() => () => { setAlerter(false) });
            setAction2(() => () => { setAlerter(false) });
            setAlerter(true);

            setOtpBtnText('try again');
        }
        else if (password.trim().length < 5) {
            //alert("Password too short");
            setMessager('Password too short');
            setOption1('Close');
            setOption2('Okay');
            setAction1(() => () => { setAlerter(false) });
            setAction2(() => () => { setAlerter(false) });
            setAlerter(true);

            setBtnText('try again');
        }
        else if (password != cpass) {
            //alert("Passwords don't match");
            setMessager('The passwords do not match');
            setOption1('Close');
            setOption2('Okay');
            setAction1(() => () => { setAlerter(false) });
            setAction2(() => () => { setAlerter(false) });
            setAlerter(true);

            setBtnText('try again');
        }
        else {
            var data = JSON.stringify({
                "contact": "254" + contact,
                "otp": otp,
                "password": password
            });

            var config = {
                method: 'post',
                url: 'https://dakabets.web.app/auth/reset',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    if (response.data === 'invalid otp') {

                        setMessager('You have entered the wrong otp code');
                        setOption1('Close');
                        setOption2('Okay');
                        setAction1(() => () => { setAlerter(false) });
                        setAction2(() => () => { setAlerter(false) });
                        setAlerter(true);

                        //alert('Wrong otp code');
                        setOtpBtnText('wrong code');
                    }
                    else if (response.data === 'OTP has expired') {
                        setMessager('That OTP has expired. Request for a new one.');
                        setOption1('Close');
                        setOption2('Request');
                        setAction1(() => () => { setAlerter(false) });
                        setAction2(() => () => { setConfirm(false) });
                        setAlerter(true);

                        //alert('OTP has expired');
                        setOtpBtnText('otp expired');
                    }
                    else {
                        firebase.auth().signInWithCustomToken(response.data)
                            .then((userCredential) => {
                                // Signed in
                                var user = userCredential.user;
                                console.log(user);
                                window.location.href = '/';
                                // ...
                            })
                            .catch((error) => {
                                var errorCode = error.code;
                                var errorMessage = error.message;
                                console.log(errorMessage);
                                //alert('Problem verifying your otp');
                                setMessager('Problem verifying your otp. Contact customer care or try again.');
                                setOption1('Try Again');
                                setOption2('Contact Customer Care');
                                setAction1(() => () => { setAlerter(false) });
                                setAction2(() => () => { location.href = 'tel:0713570000' });
                                setAlerter(true);

                                setOtpBtnText('try again');
                                // ...
                            });
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                    setMessager('Problem verifying your otp. Contact customer care or try again.');
                    setOption1('Try Again');
                    setOption2('Contact Customer Care');
                    setAction1(() => () => { setAlerter(false) });
                    setAction2(() => () => { location.href = 'tel:0713570000' });
                    setAlerter(true);
                    setOtpBtnText('try again');
                    
                });
        }
    }

    return (
        <div className="login">

            <div className="login-container">
                <div className="login-header">
                    {/* <h1 className='squid login-logo'>SQUIDGAME</h1> */}
                    <img className="login-logo-img" src={logo}/>
                </div>
                {confirm ?
                    <>
                        <div className="login-input-container">
                            <p className="login-contact-text">Enter the sent OTP</p>
                            <input type="contact" onInput={e => setOtp(e.target.value)} className="login-input login-input-register" placeholder="OTP CODE" />
                            <input type="password" onInput={e => setPassword(e.target.value)} className="login-input login-input-register" placeholder="Password" />
                            <input type="password" onInput={e => setCpass(e.target.value)} className="login-input login-input-register" placeholder="Confirm Password" />
                        </div>
                        <button className="login-btn" onClick={verify}>{otpBtnText}</button>

                    </>
                    :
                    <>
                        <div className="login-input-container">
                            <p className="login-contact-text">Enter your Contact</p>
                            <div className="login-preinput">
                                <p style={{ color: 'white' }}>+254</p>
                                <input className="login-input" type="number" placeholder="Contact" onInput={e => setContact(e.target.value)} />
                            </div>
                        </div>
                        <button className="login-btn" onClick={login}>{btnText}</button>
                    </>
                }
                <p className="login-disclaimer">By using this platform I attest that I am at least 18 years old and have read and agree to the  <a href='/terms' className="login-term">Terms of Service.</a></p>

            </div>
            {alerter && <Alerter message={messager} option1={option1} option2={option2} action1={action1} action2={action2} />}

        </div>
    );
};

export default Forgot;