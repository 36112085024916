import './style';
import Home from "./routes/home";
import Login from './routes/auth/login';
import Register from './routes/auth/register';
import Forgot from './routes/auth/forgot';
import Router from "preact-router";
import { useState, useEffect } from 'preact/hooks';
import Admin from './routes/admin';
import Terms from './routes/terms'

export default function App() {
	const [uid, setUid] = useState('none');
	const [name, setName] = useState('');
	const [userUpdate, setUserUpdate] = useState('');

	useEffect(() => {
		firebase.auth().onAuthStateChanged(function (user) {
			// user.updateProfile({
			//   displayName: 'none',
			// });
			if (user) {
				//setLoggedIn(true);
				setUid(user.uid);
				//console.log(user.displayName);
				firebase.database().ref("users/" + user.uid + '/name/').once('value').then(snapshot => {
					let name =snapshot.val();
					setName(name);
					//console.log(snapshot.val());
				});
				// if (user.displayName) {
				// 	setName(user.displayName);
				// }
				// else {
				// 	setName('none');
				// }
				//setLoading(false);

			} else {
				setUid('none');
				//setLoggedIn(false);
				//setLoading(false);
			}
		});
	}, [userUpdate]);

	const userUpdates = (data) => {
		setUserUpdate(data)
	}
	return (
		<div>
			<Router>
				<Home path="/" uid={uid} name={name} userUpdate={userUpdates} />
				<Login path="/login" />
				<Forgot path="/forgot" />
				<Register path="/register" />
				{name === 'support' && <Admin path='/admin' uid={uid} name={name}/>}
				<Terms path='/terms'/>
			</Router>
		</div>
	);
}
