import { h, Component } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import axios from 'axios';
import Alerter from '../../../components/Alerter';
import logo from "../../../assets/logo.png";

const Register = () => {
    const [btnText, setBtnText] = useState('Register');
    const [contact, setContact] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [cpass, setCpass] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [otpBtnText, setOtpBtnText] = useState('Verify OTP');

    //custom alerts
    const [alerter, setAlerter] = useState(false);
    const [messager, setMessager] = useState('');
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [action1, setAction1] = useState(() => () => { () => setAlerter(false) });
    const [action2, setAction2] = useState(() => () => { () => setAlerter(false) });
    //custom alerts


    useEffect(() => {
        let importedContact = window.location.href.substring(window.location.href.indexOf("?"), window.location.href.length);
        let IContact = decodeURI(importedContact.substring(9));
        setContact(IContact);
    }, [])




    const login = () => {
        console.log('Login presssed');
        setBtnText('please wait....');
        if (contact.trim().length !== 9) {
            setMessager('please enter a valid contact');
            setOption1('Close');
            setOption2('Okay');
            setAction1(() => () => { setAlerter(false) });
            setAction2(() => () => { setAlerter(false) });
            setAlerter(true);

            //alert('please enter a valid contact');
            setBtnText('try again');
        }
        else if (password.trim().length < 5) {
            setMessager('Password too short');
            setOption1('Close');
            setOption2('Okay');
            setAction1(() => () => { setAlerter(false) });
            setAction2(() => () => { setAlerter(false) });
            setAlerter(true);

            //alert("Password too short");
            setBtnText('try again');
        }
        else if (password != cpass) {
            setMessager('The passwords do not match');
            setOption1('Close');
            setOption2('Okay');
            setAction1(() => () => { setAlerter(false) });
            setAction2(() => () => { setAlerter(false) });
            setAlerter(true);

            //alert("Passwords don't match");
            setBtnText('try again');
        }
        else {
            setBtnText('Requesting OTP...');
            var data = JSON.stringify({
                "contact": "254" + contact,
                "password": password
            });
            var config = {
                method: 'post',
                url: 'https://dakabets.web.app/auth/register',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data === 'An account is already registered to this number') {

                        setMessager('An account is already registered to this number. Would you like to log in instead');
                        setOption1('Try Again');
                        setOption2('Login');
                        setAction1(() => () => { setAlerter(false) });
                        setAction2(() => () => { location.href = `/login?contact=${contact}` });
                        setAlerter(true);

                        //alert('An account is already registered to this number. Log in instead');
                        setOtpBtnText('try again');
                    }
                    else {
                        setConfirm(true);
                    }
                })
                .catch(function (error) {
                    setMessager('Problem creating your account. Contact customer care');
                    setOption1('Try Again');
                    setOption2('Contact Customer Care');
                    setAction1(() => () => { setAlerter(false) });
                    setAction2(() => () => { location.href = 'tel:0713570000' });
                    setAlerter(true);

                    console.log(error);
                });
        }
    }


    const verify = () => {
        setOtpBtnText('Verifying OTP...')
        if (otp.trim().length < 4) {
            setMessager('Enter a valid otp code');
            setOption1('Close');
            setOption2('Okay');
            setAction1(() => () => { setAlerter(false) });
            setAction2(() => () => { setAlerter(false) });
            setAlerter(true);

            //alert('Enter a valid otp code');
            setOtpBtnText('try again');

        }
        else {


            var data = JSON.stringify({
                "contact": "254" + contact,
                "otp": otp
            });

            var config = {
                method: 'post',
                url: 'https://dakabets.web.app/auth/create',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    if (response.data === 'invalid otp') {
                        setMessager('You have entered the wrong otp code');
                        setOption1('Close');
                        setOption2('Okay');
                        setAction1(() => () => { setAlerter(false) });
                        setAction2(() => () => { setAlerter(false) });
                        setAlerter(true);

                        //alert('Wrong otp code');
                        setOtpBtnText('wrong code');
                    }
                    else if (response.data === 'OTP has expired') {
                        setMessager('That OTP has expired. Request for a new one.');
                        setOption1('Close');
                        setOption2('Request');
                        setAction1(() => () => { setAlerter(false) });
                        setAction2(() => () => { setConfirm(false) });
                        setAlerter(true);

                        //alert('OTP has expired');
                        setOtpBtnText('otp expired');
                    }

                    else {
                        firebase.auth().signInWithCustomToken(response.data)
                            .then((userCredential) => {
                                // Signed in
                                var user = userCredential.user;
                                console.log(user);
                                window.location.href = '/';
                                refer(userCredential.user.uid);
                                // ...
                            })
                            .catch((error) => {
                                var errorCode = error.code;
                                var errorMessage = error.message;
                                console.log(errorMessage);
                                setMessager('Problem verifying your otp. Contact customer care or try again.');
                                setOption1('Try Again');
                                setOption2('Contact Customer Care');
                                setAction1(() => () => { setAlerter(false) });
                                setAction2(() => () => { location.href = 'tel:0713570000' });
                                setAlerter(true);

                                //alert('Problem verifying your otp');
                                setOtpBtnText('try again');
                                // ...
                            });
                    }
                })
                .catch(function (error) {
                    setMessager('Problem verifying your otp. Contact customer care or try again.');
                    setOption1('Try Again');
                    setOption2('Contact Customer Care');
                    setAction1(() => () => { setAlerter(false) });
                    setAction2(() => () => { location.href = 'tel:0713570000' });
                    setAlerter(true);
                    setOtpBtnText('try again');

                    //console.log(error);
                });
        }
    }

    const refer = (uid) => {

        let undecoded = window.location.href.substring(window.location.href.indexOf("#"), window.location.href.length);
        let referer = decodeURI(undecoded);
        if (referer.startsWith('#')) {
            if (referer.length > 3) {
                firebase.database().ref('refer/' + uid + '/').set({
                    name: referer.substring(1),
                });
            }
        }
    }

    return (
        <div className="login">

            <div className="login-container">
                <div className="login-header">
                    <img className="login-logo-img" src={logo}/>
                    {/* <h1 className='squid login-logo'>SQUIDGAME</h1> */}
                </div>
                {confirm ?
                    <>
                        <div className="login-input-container">
                            <p className="login-contact-text">Enter the sent OTP</p>
                            <input type="number" onInput={e => setOtp(e.target.value)} className="login-input login-input-register" placeholder="OTP CODE" />
                        </div>
                        <button className="login-btn" onClick={verify}>{otpBtnText}</button>

                    </>
                    :
                    <>
                        <div className="login-input-container">
                            <p className="login-contact-text">Enter your Contact</p>
                            <div className="login-preinput">
                                <p style={{ color: 'white' }}>+254</p>
                                <input className="login-input" value={contact} type="number" placeholder="Contact" onInput={e => setContact(e.target.value)} />
                            </div>
                            <input type="password" onInput={e => setPassword(e.target.value)} className="login-input login-input-register" placeholder="Password" />
                            <input type="password" onInput={e => setCpass(e.target.value)} className="login-input login-input-register" placeholder="Confirm Password" />

                        </div>
                        <button className="login-btn" onClick={login}>{btnText}</button>
                    </>
                }
                <p className="login-disclaimer">By using this platform I attest that I am at least 18 years old and have read and agree to the  <a href='/terms' className="login-term">Terms of Service.</a></p>
            </div>
            {alerter && <Alerter message={messager} option1={option1} option2={option2} action1={action1} action2={action2} />}

        </div>
    );
};

export default Register;